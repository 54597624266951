// CreateAccount.js

import React from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import app from '../firebase';
import { getFirestore, collection, query, where, getDocs, doc, setDoc  } from "@firebase/firestore";
import { format } from 'date-fns';

import {Card,CardContent,CardDescription,CardFooter,CardHeader,CardTitle,} from "../components/ui/card"
import {Form,FormControl,FormDescription,FormField,FormItem,FormLabel,FormMessage,} from "../components/ui/form"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Textarea } from "../components/ui/textarea"
import { Dialog,DialogContent, DialogDescription,DialogHeader,DialogTitle,DialogTrigger,DialogFooter,DialogClose} from "../components/ui/dialog"
import { Label } from "../components/ui/label"
import { SelectValue, SelectTrigger, SelectItem, SelectContent, Select, SelectGroup } from "../components/ui/select"
import { Checkbox, CheckboxGroup } from "../components/ui/checkbox"

const db = getFirestore(app);

const CreateAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const activeSubscription = params.get('activeSubscription');

  const formSchema = z.object({
    firstName: z.string().min(1, {
      message: "First name is required.",
    }),
    lastName: z.string().min(1, {
      message: "Last name is required.",
    }),
    email: z.string().email({
      message: "Invalid email address.",
    }).min(6, {
      message: "Email addresses must be at least 6 characters.",
    }),
    university: z.string().min(1, { message: "University is required." }),
    gradYear: z.string().length(4, { message: "Graduation year must be exactly 4 characters." }),
    recruitingTarget: z.string().min(1, { message: "Recruiting target is required." }),
    termsAcceptance: z.literal(true, {
      errorMap: () => ({ message: "You must accept the terms and conditions." }),
    }),
    copyingAcceptance: z.literal(true, {
      errorMap: () => ({ message: "You must accept the terms and conditions." }),
    }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      university: "",
      gradYear: "",
      recruitingTarget: "",
      termsAcceptance: false,
      copyingAcceptance: false,
    },
  });

  async function onSubmit(data) {
    // console.log(data);
    const now = new Date();
    const formattedDateTime = format(now, 'yyyy-MM-dd HH:mm:ss');
    var docId = email;
    if (docId == null) docId = data.email;
    setDoc(doc(db, "usersInfo", docId), {
      ...data,
      createdAt: formattedDateTime,
    });
    if (activeSubscription != null && activeSubscription) {
      navigate('/platform');
    } else {
      navigate('/pricing');
    }
  }

  return (
    <div>
      <Navbar/>
      <div className='mt-20 flex justify-center mx-4'> 
        <div className='max-w-lg w-full'>
        <h1 className="mt-4 mb-0 text-2xl font-bold text-center">Create an account</h1>
        <h2 className='text-lg text-gray-500 text-center mb-6'>Enter your information below to create your account</h2>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 max-w-lg">
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="firstName">First Name</Label>
                    <FormControl>
                      <Input id="firstName" placeholder="John" type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="lastName">Last Name</Label>
                    <FormControl>
                      <Input id="lastName" placeholder="Doe" type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="email">School/Work Email</Label>
                  <FormControl>
                    <Input id="email" placeholder="john.doe@example.com" type="email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="university"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="university">University</Label>
                    <FormControl>
                      <Input id="university" placeholder="University of Chicago" type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="gradYear"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="gradYear">Graduation Year</Label>
                    <FormControl>
                      <Input id="gradYear" placeholder="2027" type="number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
                control={form.control}
                name="recruitingTarget"
                render={({ field }) => (
                  <FormItem>
                    <Label htmlFor="recruitingTarget">Recruiting Target</Label>
                    <FormControl>
                      <Select
                        id="recruitingTarget"
                        required
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <SelectTrigger className="font-normal ml-0 outline outline-[1px] outline-slate-200 text-slate-400 hover:bg-white">
                          <SelectValue placeholder="Summer Analyst" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="summer-analyst">Summer Analyst</SelectItem>
                          <SelectItem value="full-time-analyst">Full-Time Analyst</SelectItem>
                          <SelectItem value="associate">Associate</SelectItem>
                          <SelectItem value="other">Other</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            <FormField
              control={form.control}
              name="termsAcceptance"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <input id="termsAcceptance" type="checkbox" className="w-4 h-4 ml-2 mr-2 text-primary bg-gray-100 border-gray-300 rounded" {...field} />
                  </FormControl>
                  <Label htmlFor="termsAcceptance">I agree to the <a href="/terms-of-service" target='_blank' className="text-light-blue hover:underline">terms of service</a>.</Label>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="copyingAcceptance"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <input id="copyingAcceptance" type="checkbox" className="w-4 h-4 ml-2 mr-2 text-primary bg-gray-100 border-gray-300 rounded" {...field} />
                  </FormControl>
                  <Label htmlFor="copyingAcceptance">I agree that I will face legal consequences for copying or distributing Overlevered's questions and/or answers, as stated in the <a href="/terms-of-service#intellectual-property" target='_blank' className="text-light-blue hover:underline">terms of service</a>.</Label>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full ml-0" type="submit">Create Account</Button>
          </form>
        </Form>
      </div>
      </div>
    </div>
  );
};

export default CreateAccount;
