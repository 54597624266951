import logo from './logo.svg';
import './App.css';
import { Button } from "./components/ui/button"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Pages
import Signin from './components/Signin';
import Cards from './components/Cards';
import Checkout from './components/Checkout';
import CheckoutSuccess from './components/CheckoutSuccess';
import TermsOfService from './components/TermsOfService';
import Blog from './components/Blog/Blog';
import Evercore from './components/Blog/Evercore';
import MorganStanley from './components/Blog/MorganStanley';
import Qatalyst from './components/Blog/Qatalyst';
import CreateAccount from './components/CreateAccount';


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/platform" element={<Cards />} />
          <Route path="/pricing" element={<Checkout />} />
          <Route path="/checkout-success" element={<CheckoutSuccess />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/evercore-process" element={<Evercore />} />
          <Route path="/blog/morgan-stanley-process" element={<MorganStanley />} />
          <Route path="/blog/qatalyst-process" element={<Qatalyst />} />
          <Route path="/create-account" element={<CreateAccount />}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
